@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';

.Header_root {
  height: 60px;
  //background: $primaryHeaderBg;
  @media only screen and (min-width: $desktop-width) {
    height: 64px;
  }
}

.Header_menu {
  display: block;
  cursor: pointer;
  @media only screen and (min-width: $desktop-width) {
    pointer-events: none;
    display: none;
  }  
  &--open {
    .Header_menu_icon_hamburger {
//       width: 16px;
      span {
        background-color: transparent;
        &:after {
          transform: rotate(45deg);
          top: 0;
          background: #2AF3F3;
        }
        &:before {
          width: 20px;
          transform: rotate(-45deg);
          bottom: 0;
          background: #2AF3F3;
        }
      }      
    }
  }

  &_icon_hamburger {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;

    span {
      position: absolute;
      width: 15px;
      height: 2px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #2af3f3;
      transition: all .25s;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 6px;
        width: 10px;
        height: 2px;
        background: #fff;
        transition: all .25s;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 6px;
        width: 20px;
        height: 2px;
        background: #fff;
        transition: all .25s;
      }
    }
  }
}