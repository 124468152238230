@import '@/assets/scss/_variables.scss';

.MobileBackButton_root {
  padding: 0 10px;
  margin: 0 0 20px;
  display: block;
  cursor: pointer;
  @media only screen and (min-width: $tablet-width) {
    display: none;
  }
}

.MobileBackButton_wrapper {
  display: flex;
  align-items: center;
}

.MobileBackButton_icon {
  margin-right: 14px;
}

.MobileBackButton_title {
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #2AF3F3;
  text-shadow: 0px 0px 10px #102032;
}