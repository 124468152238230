@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';

.navigation-root {
  @include scrollbar;
}

.Navigation_logoWrapper {
  &Border {
    width: 120px;
    height: 1px;
    background: $primaryLanding;
    opacity: 0.21;
    margin: 5px 0 0 0;
  }

  &Subtitle {
    margin: 5px 0 0 0;
    letter-spacing: 0.21em;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#2af3f3, #11bcee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  img {
    max-width: 52%;
    min-width: 120px;
    cursor: pointer;
  }
}

.navigation-container-menu-title {
  &--sml {
    padding-right: 15px;
  }

  &--soon {
    &:before {
      content: 'coming soon';
      position: absolute;
      right: 10px;
      top: 3px;
      font-size: 8px;
      line-height: 9px;
      letter-spacing: 0.04em;
      color: #2af3f3;

      @media only screen and (min-width: $large-width) {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}

.Navigation_parentLinkWrapper {
  margin-bottom: 13px;

  .Navigation_parentLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .Navigation_parentLinkText {
      color: $primaryText;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-left: 15px;
      text-transform: uppercase;
    }

    .Navigation_parentLinkIcon {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 2px;
      background-color: rgba(42, 243, 243, 0.5);
      position: relative;

      img {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
      }
    }
  }
  &.Navigation_activeLink {
    .Navigation_parentLink {
      .Navigation_parentLinkIcon {
        background-color: #2af3f3;
      }
      .Navigation_parentLinkText {
        color: $thirdColorText;
      }
    }
  }
}

.Navigation_childLinkWrapper {
  margin: 1px 0 0px 0;
  text-align: left;
  padding-left: 45px;
  .Navigation_childLink {
    cursor: pointer;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 2px;
    background-color: transparent;
    color: $secondaryText;
    font-size: 12px;
    line-height: 14px;
  }
  &.Navigation_activeLink {
    .Navigation_childLink {
      color: $thirdColorText;
      background-color: rgba($thirdColorText, 0.1);
    }
  }
}

.Navigation_navContainer-menu {
  // Providers icons menu start
  &Providers {
    .TreeNavigation {
      .NavigationLevel--level-0 {
        & > ul {
          & > li {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              // Icon without children elements
              &::before {
                content: '';
                width: 30px;
                height: 30px;
                border-radius: 2px;
                background-color: #16293f;
                background-position: center;
                background-repeat: no-repeat;
                margin: 0 14px 0 0;
                background-image: url('../../../assets/img/auth/menu-ex.svg') !important;
              }
            }
          }
        }
      }
    }
  }
  // Providers icons menu end
  .TreeNavigation {
    width: 100%;
    .NavigationLevel--level-0 {
      & > ul {
        padding: 0;
        & > li {
          display: flex;
          align-items: center;
          padding: 0;
          margin: 0 0 10px 0;
          @media only screen and (min-width: $large-width) {
            margin: 0 0 14px 0;
          }
          &:last-child {
            margin: 0;
          }
          & > .NavigationItem a {
            position: relative;
            display: flex;
            letter-spacing: 0.08em;
            // text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            align-items: center;
          }
          .NavigationLevel__parent {
            &:before {
              display: none !important;
            }
          }
          &:nth-child(1) {
            .NavigationLevel__parent {
              &::before {
                background-image: url('../../../assets/img/auth/menu-ex.svg');
              }
            }
          }
          &:nth-child(2) {
            .NavigationLevel__parent {
              &::before {
                background-image: url('../../../assets/img/auth/menu-sett.svg');
              }
            }
          }
        }
      }
    }
    .NavigationLevel--level-2 {
      padding: 0 0 0 12px;
      .NavigationItem__router-link.router-link-exact-active {
        background: none !important;
      }
      .NavigationLevel__children {
        & > li {
          padding: 0 0 0 29px;
        }
      }
      .NavigationLevel__parent {
        &::before {
          display: none;
        }
        .NavigationItem a {
          padding-left: 0px;
          text-transform: inherit;
        }
      }
    }
    .NavigationItem {
      padding: 0;
    }
    .NavigationItem__router-link {
      color: #a6aebf;
      text-decoration: none;
      &.router-link-active {
        color: #2af3f3;
        &::before {
          border-color: #2af3f3 transparent transparent transparent !important;
        }
      }
    }
    .NavigationToggle {
      opacity: 0;
      width: 12px;
      padding: 0px 5px 0 3px;
    }
    .NavigationLevel {
      &.NavigationLevel--closed {
        .NavigationItem__router-link {
          &::before {
            transform: rotate(0);
          }
        }
      }
    }
    .NavigationLevel__parent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 3px 0;
      @media only screen and (min-width: $large-width) {
        margin: 0 0 6px 0;
      }
      &::before {
        content: '';
        width: 30px;
        height: 30px;
        border-radius: 2px;
        background-color: #16293f;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 14px 0 0;
      }
      .NavigationItem__router-link {
        position: relative;
        display: flex;
        letter-spacing: 0.08em;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        &::before {
          position: absolute;
          left: -12px;
          pointer-events: none;
          content: '';
          border-width: 4px 3px 0 3px;
          border-color: #a6aebf transparent transparent transparent;
          width: 0;
          height: 0;
          border-style: solid;
          transform: rotate(180deg);
        }
      }
    }
    .NavigationLevel__children {
      padding: 0;
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 3px 0 3px 25px;
        @media only screen and (min-width: $desktop-width) {
          padding: 0 0 0 21px;
          margin: 0 0 3px;
        }
        @media only screen and (min-width: $large-width) {
          margin: 0 0 5px;
        }
        &:last-child {
          margin: 0;
        }
        .NavigationItem__router-link {
          padding: 6px 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.04em;
          &.router-link-exact-active {
            background: #133545;
            border-radius: 2px;
          }
          @media only screen and (min-width: $large-width) {
            padding: 5px 10px;
          }
        }
      }
    }
  }
}

.Navigation_containerSettings {
  padding: 20px 36px;
  background: #15293e;
  box-shadow: inset 0px 8px 10px rgba(34, 39, 47, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (min-width: $large-width) {
    padding: 20px 30px;
  }
  .Navigation_containerSettingsTitle {
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &.router-link-active {
      color: #2af3f3;
    }
    &Margin {
      margin-bottom: 14px;
    }
    img {
      margin: 0 8px 0 0;
      height: 19px;
      @media only screen and (min-width: $large-width) {        
        height: 22px;
      }
    }
  }
  .Navigation_containerSettingsItem {
    color: #a6aebf;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-decoration: none;
    margin: 0 0 0 19px;
    padding: 5px 10px;
    border-radius: 2px;

    &Margin {
      margin-bottom: 15px;
    }
    &.router-link-active {
      color: #2af3f3;
      background: #133545;
    }
  }
}
