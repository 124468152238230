@import '@/assets/scss/_variables.scss';
.ExtraFilter {
  text-align: left;

  &--open {
    position: relative;
    z-index: 9;
  }

  &--inlineRight {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
  }

  &_Label {
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }

  &_InputArea {
    border: 1px solid transparent;

    &Wrapper {
      position: relative;
    }

    .ExtraFilter--inlineRight & {
      background-color: transparent;
    }
  }

  &_arrow {
    color: #fff;
    transition: all .25s;
    user-select: none;

    .ExtraFilter--open & {
      transform: rotate(180deg);
    }

    .ExtraFilter_drowDown & {
      margin: 0 12px 0 4px;
      cursor: pointer
    }
  }

  &_drowDown {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    border-radius: 4px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 18px 34px rgba(34, 39, 47, 0.55);

    .ExtraFilter--open & {
      opacity: 1;
      visibility: visible;
    }
  }

  &_filterAll {
    grid-area: all;
    font-size: 14px;
    line-height: 16px;
    color: #A6AEBF;
    cursor: pointer;

    @media only screen and (min-width: $large-width) {
      font-size: 16px;
      line-height: 18px;
    }

    span {
      padding: 12px;
    }
  }
}