@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















































































































@import './Application.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
