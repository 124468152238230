@import '@/assets/scss/_variables.scss';

.Navigation_Menu {
    row-gap: 8px;

    &Item {
        --iconSide: 30px;
        --columnGap: 11px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 4px;

        .Navigation_MenuItemSubMenu & {
            --iconSide: 0;
        }

        &Icon {
            order: 10;
            width: var(--iconSide);
            height: var(--iconSide);
            background-color: rgba(#50C5E9, .21);
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;

            svg {
                --side: 100%;
                width: var(--side);
                height: var(--side);
                object-fit: scale-down;
                object-position: 50% 50%;
            }
        }
        

        &Arrow {
            order: 20;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin: 0 4px 0 var(--columnGap);
            margin: 0 6px 0 var(--columnGap);
            cursor: pointer;

            &:before {
                content: "";
                position: relative;
                top: -1px;
                border-style: solid;
                border-width: 3px 3px 0 3px;
                border-color: #A6AEBF transparent transparent transparent;
                transition: all .25s;
            }

            &:after {
                content: "";
                position: absolute;
                --side: 20px;
                width: var(--side);
                height: var(--side);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            .router-link-active + & {
                &:before {
                    transform: rotate(180deg);
                    top: -3px;
                    border-color: #2AF3F3 transparent transparent transparent !important;
                }
            }

            .Navigation_MenuItem--hidden .router-link-active + & {
                &:before {
                    transform: none;
                    top: -1px;
                    border-color: #A6AEBF transparent transparent transparent !important;
                }
            }

            .Navigation_MenuItem--open & {
                &:before {
                    transform: rotate(180deg);
                    top: -3px;
                }
            }
        }

        &Link {
            order: 30;
            display: inline-flex;
            align-items: center;
            max-width: calc(100% - var(--iconSide) - var(--columnGap));
            padding: 4px var(--columnGap);
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-decoration: none;
            transition: all .25s;
            color: #0F1E30 !important;

            &.router-link-active,
            &:hover {
                color: #50C5E9 !important;
            }

            .Navigation_MenuItemSubMenu & {
                font-weight: 400;

                &.router-link-active {
                    background-color: rgba(#50C5E9, .21);
                    border-radius: 2px;
                }
            }
            .Navigation_MenuItem--hasChildren > &{
                padding-left: 0;
            }
        }

        &SubMenu {
            order: 40;
            display: none;
            --offsetLeft: 30px;
            flex-basis: 100%;
            flex-direction: column;
            padding: 0 0 0 calc(var(--iconSide) + var(--offsetLeft));
            @media only screen and (min-width: $desktop-width) {
                --offsetLeft: 20px;
            }
            @media only screen and (min-width: $large-width) {
                --offsetLeft: 30px;
            }

            .Navigation_MenuItem--open &,
            :not(.Navigation_MenuItem--hidden) .router-link-active+i+& {
                display: flex;
            } 
            
            .Navigation_MenuItem--hidden & {
                display: none !important;
            }
        }
    }
}

body {
  &.dark {
    .Navigation_Menu {
      &Item {
        &Icon {
          background-color: rgba(#2D4D70, .21);
        }
        &Link {
          color: #A6AEBF !important;

          &.router-link-active,
          &:hover {
            color: #2af3f3 !important;
          }
          .Navigation_MenuItemSubMenu & {
            font-weight: 400;

            &.router-link-active {
              background-color: #133545;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}