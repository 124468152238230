@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';




































.custom-check {
  .switch:after,
  .switch-one:after {
    content: '';
    transition: all .3s;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #2AF3F3;
    box-shadow: 0 0 10px rgba(#2AF3F3, .55);
    top: 5px;
    transform: translateX(-20px);
  }

  .checkbox:checked + .switch:after {
    transition: all .3s;
    right: 5px;
    transform: translateX(0);
  }
}
