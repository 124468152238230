@import '@/assets/scss/_mixins.scss';
@import '@/assets/scss/_variables.scss';

.application-nav {
  position: absolute;
  left: -120%;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
  top: 60px;
  z-index: 9999;
  width: 100%;

  &--shown {
    left: 0;
    bottom: 0;
    opacity: 1;
    visibility: visible;
  }

  @media only screen and (min-width: $desktop-width) {
    width: auto;
    position: relative;
    top: auto;
    left: auto;
    opacity: 1;
    visibility: visible;
    flex: 0 1 230px;
  }

  @media only screen and (min-width: $large-width) {
    flex: 0 1 260px;
  }

  @media only screen and (min-width: $wide-width) {
    flex: 0 1 290px;
  }
}

.application-content {
  flex: 1;
  max-width: 100%;

  @media only screen and (min-width: $desktop-width) {
    max-width: calc(100% - 230px - 20px - 20px);
    margin: 0 20px;
  }
  @media only screen and (min-width: $large-width) {
    max-width: calc(100% - 260px - 20px - 20px);
  }
  @media only screen and (min-width: $wide-width) {
    max-width: calc(100% - 290px - 20px - 20px);
  }
}

.application-container {
  --offset-top: 20px;
  margin-top: var(--offset-top);
  height: calc(100vh - 60px - var(--offset-top));
  overflow-x: hidden;
  overflow-y: auto;
  @include scrollbar;
  @media only screen and (min-width: $desktop-width) {
    height: calc(100vh - 64px - var(--offset-top));
  }
}