@import '@/assets/scss/_mixins.scss';
.avatar-panel-settings {
  .avatar-icon {
    margin: 0 10px 0 0 !important;
  }
}
.avatar-panel-root {
  .avatar-icon {
    @media screen and (max-width: 480px) {
      padding: 6px 10px 6px 30.5px;
      margin: -7px -10px 0px 8px;
    }

    &:after {
      content: "";
      display: none;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: #2AF3F3 transparent transparent transparent;
      margin-left: 6px;
      cursor: pointer;
      transition: all .25s;

      @media only screen and (min-width: $mobile-width) {
        display: none;
      }
    }

    &.open {
      @media screen and (max-width: 480px) {
        background-color: #1F3347;
      }
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .avatar-icon-user {
    width: 100%;
    height: 100%;
  }

  .avatar-panel-body {
    &.avatar-panel-dropdown {
      display: flex;
      position: absolute;
      right: 0;
      top: 100%;
      background-color: #1F3347;
      padding: 12px 10px;
      justify-content: flex-end;
      opacity: 0;
      visibility: hidden;
      transition: all .25s;
      border-radius: 0 0 4px 4px;

      @media only screen and (min-width: $mobile-width) {
        display: none;
        position: relative;
        right: auto;
        top: auto;
        width: auto;
        background: transparent;
        padding: 0;
        opacity: 1;
        visibility: visible;
        margin: 0;
      }

      &.open {
        opacity: 1;
        visibility: visible;
      }

      .avatar-panel-more {
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: $mobile-width) {
          display: none;
        }
      }
    }

    .avatar-panel-more {
      display: none;
    }
  }

  &--dropdown {
    .avatar-icon {
      &:after {
        display: block;

        @media only screen and (min-width: $mobile-width) {
          display: none;
        }
      }
    }
  }
}